const URL = process.env.REACT_APP_API_URL;

const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export const exportExcel = async (filters) => {
  const response = await fetch(`${URL}/export/excel`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filters),
  });
  if (!response.ok) throw new Error(`Error: ${response.statusText}`);
  return response;
}

export const exportSubgroupsExcel = async (filters) => {
  const response = await fetch(`${URL}/export/subgroups`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filters),
  });
  if (!response.ok) throw new Error(`Error: ${response.statusText}`);
  return response;
}

export const exportDraw = async (filters) => {
  const response = await fetch(`${URL}/export/draw`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filters),
  });
  if (!response.ok) throw new Error(`Error: ${response.statusText}`);
  return response;
}