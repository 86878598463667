import React, { useEffect, useState, useCallback } from "react";
import { saveFilter, saveDraw, updateFilter, fetchFilters, applyFilter, removeFilter } from "../services/filterService";
import { saveGroup, fetchGroups, applyGroup, removeGroup } from "../services/filterGroupService";
import { filterTable } from "../services/displayDataService";
import { handleCatch } from "../utils/handleCatch";

import Filter from "./Filter";
import FilterGroup from "./FilterGroup";

import SaveFiltersForm from "./forms/FilterList/SaveFiltersForm";
import SaveCombinationForm from "./forms/FilterList/SaveCombinationForm";
import FilterGroupCombinationForm from "./forms/FilterList/FilterGroupCombinationForm";
import ExecuteProcedureForm from "./forms/FilterList/ExecuteProcedureForm";

const FiltersList = ({
  filters,
  setData,
  setMeta,
  setFilters,
  onReset,
  onUpdateLoading,
}) => {
  const [filterList, setFilterList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isSaveFormActive, setIsSaveFormActive] = useState(false);
  const [isDrawFormActive, setIsDrawFormActive] = useState(false);
  const [isFilterGroupCombinationFormActive, setIsFilterGroupCombinationFormActive] = useState(false);
  const [isProcedureFormActive, setIsProcedureFormActive] = useState(false);

  // Stores a new filter into the db
  const handleSaveFilter = async (formData) => {
    try { await saveFilter(formData); } 
    catch (err) { handleCatch(err); } 
    finally {
      fetchData();
      setIsSaveFormActive(false);
      setFormData(null);
    }
  }

  // Updates a filter with the current filter data
  const handleUpdateFilter = async (formData) => {
    try { await updateFilter(formData); } 
    catch (err) { handleCatch(err); } 
    finally {
      fetchData();
      setIsSaveFormActive(false);
      setFormData(null);
    }
  }

  // Saves a new filter with a specific combination data
  const handleSaveCombination = async (formData) => {
    try { await saveDraw(formData); } 
    catch (err) { handleCatch(err); }
    finally {
      fetchData();
      setIsDrawFormActive(false);
      setFormData(null);
    }
  }

  // Stores a new group into the db
  const handleSaveGroup = async () => {
    const newName = prompt("Nombre del grupo:");
    if (!newName) return;
    try { await saveGroup({ name: newName }); } 
    catch (err) { handleCatch(err); } 
    finally { fetchData(); }
  }

  // Aplies a filter without modifying the table
  const handleFilterTable = async () => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await filterTable(filters);
      setData(data);
      setMeta(meta);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  };

  // Creates an execution procedure to apply/remove filters and groups in some order
  const executeProcedure = async (executionList) => {
    onUpdateLoading(true);
    const filters = executionList.filter((item) => item.type === 'filter');
    const groups = executionList.filter((item) => item.type === 'group');
    const sortedGroups = groups.sort((a, b) => a.count - b.count);

    // Combine and prioritize: first "apply" then "remove"
    const orderedFilters = [
      ...filters.filter((item) => item.action === 'apply'),
      ...filters.filter((item) => item.action === 'remove'),
    ];

    const orderedGroups = [
      ...sortedGroups.filter((item) => item.action === 'apply'),
      ...sortedGroups.filter((item) => item.action === 'remove'),
    ];

    try {
      // Process filters one by one
      for(const filter of orderedFilters) {
        const { filterID, action } = filter;
        action === "apply" 
          ? await applyFilter(filterID)
          : await removeFilter(filterID);
      }

      // Process groups one by one
      for (const group of orderedGroups) {
        const { groupID, action } = group;
        action === "apply" 
          ? await applyGroup(groupID)
          : await removeGroup(groupID);
      }
    }
    catch(err) { handleCatch(err); }
    finally { onReset(); }
  }

  // Gets all filters and groups stored in the db
  const fetchData = useCallback(async () => {
    try {
      const [filterData, groupData] = await Promise.all([
        fetchFilters(),
        fetchGroups(),
      ]);
      setFilterList(filterData);
      setGroupList(groupData);
    } 
    catch (err) { handleCatch(err); }
  }, []);

  // Obtains data when rendering component
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // ------------------------- Component ------------------------- //
  return (
    <div>
      <h2>Filtros</h2>
      <div style={{ maxHeight: "20rem", overflow: "auto" }}>
        {/* Filters */}
        {filterList.map(({ filterID, name }, index) => (
          <div key={index}>
            <Filter 
              id={filterID} name={name} 
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              setFormData={setFormData}
              setIsFilterFormActive={setIsSaveFormActive}
              onFilterChange={fetchData}
            />
          </div>
        ))}

        {/* Groups */}
        {groupList.map(({ groupID, name, count }, index) => (
          <div key={index}>
            <FilterGroup 
              id={groupID} name={name} count={count}
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              setFormData={setFormData}
              setIsFilterFormActive={setIsSaveFormActive}
              onFetchData={fetchData}
            />
          </div>
        ))}
      </div>

      <button onClick={() => setIsSaveFormActive(true)}>Guardar filtro</button>
      <button onClick={() => setIsDrawFormActive(true)}>Guardar sorteo</button>
      <br></br>
      <button onClick={handleSaveGroup}>Crear grupo</button>
      <button onClick={() => setIsFilterGroupCombinationFormActive(true)}>Crear combinaciones</button>
      <br></br>
      <button onClick={() => setIsProcedureFormActive(true)}>Ejecutar procedimiento</button>
      <br></br>
      <button onClick={handleFilterTable}>Filtrar</button>
      <button onClick={onReset}>Reset</button>
      <br></br>
      <br></br>

      {isSaveFormActive && (
        <SaveFiltersForm
          filters={filters}
          groupList={groupList}
          formData={formData}
          setFormData={setFormData}
          setIsSaveFormActive={setIsSaveFormActive}
          onSave={handleSaveFilter}
          onUpdate={handleUpdateFilter}
        />
      )}

      {isDrawFormActive && (
        <SaveCombinationForm 
          groupList={groupList}
          setFormData={setFormData}
          setIsDrawFormActive={setIsDrawFormActive}
          onSave={handleSaveCombination}
        />
      )}

      {isFilterGroupCombinationFormActive && (
        <FilterGroupCombinationForm 
          onFormClose={(wasItemCreated) => {
            setIsFilterGroupCombinationFormActive(false);
            if (wasItemCreated) fetchData();
          }}
        />
      )}

      {isProcedureFormActive && (
        <ExecuteProcedureForm 
          setIsProcedureFormActive={setIsProcedureFormActive}
          onExecute={executeProcedure}
        />
      )}
    </div>
  );
};

export default FiltersList;
