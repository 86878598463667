import { useEffect, useState, useMemo, useCallback } from "react";
import Button from "../../Button";
import { fetchColumns } from "../../../services/displayDataService";
import { saveFilterGroup } from "../../../services/filterService";
import { handleCatch } from "../../../utils/handleCatch";
import "../../../css/Forms.css";
/* import styles from '../../../css/forms/FilterList/FilterGroupCombinationForm.module.css'; */

/**
 * FilterManager.jsx
 * @param {Function} onFormClose - Function to close the form and update parents filter list if a filter group was created.
 */
const FilterGroupCombinationForm = ({ onFormClose }) => {
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [type, setType] = useState("");
  const [aviableColumns, setAviableColumns] = useState({});
  const [selectedColumns, setSelectedColumns] = useState({});
  const [onlyFirst, setOnlyFirst] = useState(true);
  const [leader, setLeader] = useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const leaderArray = ["Lider 1", "Lider 2", "Lider 3", "Lider 4", "Lider 5", "Lider 6", "Lider 7", "Lider 8", "Lider 9", "Lider 10", "Lider 11", "Lider 12", "Lider 13", "Lider 14", "Lider 15", "Resto"]

  // Obtains user input, validates it and then stores the group combination
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const invalidColumn = Object.keys(selectedColumns).find(
      (column) => selectedColumns[column] === ""
    );

    if (invalidColumn) {
      alert("Selecciona un líder para cada columna.");
      return;
    } if (!name) {
      alert("Introduce el nombre del grupo de filtros.");
      return;
    } if (!Object.keys(selectedColumns).length) {
      alert("Elige por lo menos una columna");
      return;
    } if (!size) {
      alert("Introduce tamaño de las combinaciones.");
      return;
    } 

    const combinationData = {
      name,
      combinationData: selectedColumns,
      size: Number(size),
      onlyFirst,
      type: type ? type : null,
    }
    saveData(combinationData);
  }

  // Creates a group and fills it with a combination of filters
  const saveData = async (combinationData) => {
    try { await saveFilterGroup(combinationData); }
    catch (err) { handleCatch(err); }
    finally { onFormClose(true); }
  }

  // Handles leader selection behavior for filter-items
  const handleLeaderChange = (e) => {
    const newLeader = e.target.value;
    setLeader(newLeader);
  };

  // Updates the selected columns with new data
  const handleColumnChange = (column, event) => {
    setSelectedColumns((prev) => {
      const newSelected = { ...prev };
  
      if (event.ctrlKey) {
        if (newSelected[column] !== undefined) {
          delete newSelected[column];
        } else {
          newSelected[column] = leader;
        }
      } else if (event.shiftKey) {
        const columns = Object.keys(aviableColumns);
        const start = columns.findIndex((col) => col === Object.keys(prev).pop());
        const end = columns.findIndex((col) => col === column);
        const range = columns.slice(Math.min(start, end), Math.max(start, end) + 1);
  
        range.forEach((col) => {
          if (!(col in newSelected)) {
            newSelected[col] = leader;
          }
        });
      } else {
        if (!(column in newSelected)) {
          newSelected[column] = leader;
        }
      }
  
      return newSelected;
    });
  };
  const handleMouseDown = (column, event) => {
    setIsDragging(true);
    handleColumnChange(column, event);
  };
  const handleMouseMove = (column) => {
    if (isDragging) {
      setSelectedColumns((prev) => ({
        ...prev, [column]: prev[column] ?? leader,
      }));
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Filter column options
  const filteredColumns = useMemo(() => {
    let columns = Object.keys(aviableColumns);
  
    if (leader !== "") {
      columns = columns.filter((column) => aviableColumns[column] > leader);
    }
  
    if (searchTerm) {
      columns = columns.filter((column) =>
        column.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    return columns;
  }, [aviableColumns, leader, searchTerm])

  // Fetch aviable columns when rendering the component
  const fetchAllColumns = useCallback(async () => {
      try {
        const columns = await fetchColumns();
        setAviableColumns(columns);
      } 
      catch (err) { handleCatch(err); }
    }, []);

  useEffect(() => {
    fetchAllColumns();
  }, [fetchAllColumns]);

  return (
    <div className="modal-overlay" style={{ height: "auto" }}>
      <div className="modal-content" style={{ width: "26%" }}>
        <form onSubmit={handleFormSubmit}>
          {/* Name for the new group */}
          <div>
            <label>
              <b>Nombre</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Grupo de filtros"
              />
            </label>
          </div>
          
          {/* What kind of group */}
          <div>
            <label>
              <b>Tipo de grupo</b>
              <select
                value={type === "" ? "Sin tipo" : type}
                onChange={(e) => setType(e.target.value)}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin tipo</option>
                <option value="apply">Aplicar</option>
                <option value="remove">Quitar</option>
              </select>
            </label>
          </div>

          {/* Leader selection */}
          <div>
            <label>
              <b>Filtrar por líder</b>
              <select value={leader} onChange={handleLeaderChange}>
                <option value="">Selecciona un líder...</option>
                {leaderArray.map((leaderName, index) => (
                  <option key={index} value={index}>{leaderName}</option>
                ))}
              </select>
            </label>
          </div>
          
          {/* Checkbox for creation behaviour */}
          <div>
            <label className="checkbox-label">
              <span><b>{onlyFirst ? "Solo lider" : "Todas menos el lider"}</b></span>
              <div>
                <input
                  type="checkbox"
                  checked={onlyFirst}
                  onChange={(e) => setOnlyFirst(e.target.checked)}
                />
              </div>
            </label>
          </div>

          {/* Column selection */}
          <div className="filter-container">
            <label>
              <b>Filtrar por columnas</b>
              <input
                type="text"
                placeholder="Buscar columnas..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="filter-list" onMouseUp={handleMouseUp}>
                {filteredColumns.length > 0 ? (
                  filteredColumns.map((column) => (
                    <div
                      className="filter-item"
                      key={column}
                      onMouseDown={(e) => handleMouseDown(column, e)}
                      onMouseMove={() => handleMouseMove(column)}
                      style={{
                        backgroundColor: selectedColumns[column] !== undefined ? "#007bff" : "#fff",
                        color: selectedColumns[column] !== undefined ? "#fff" : "#000",
                        cursor: "pointer",
                        userSelect: "none",
                        padding: "5px",
                        margin: "2px",
                      }}
                    >
                      <span>{`P${Object.keys(aviableColumns).indexOf(column) + 1}`}</span>
                      <span>{`${column}`}</span>
                      <select 
                        value={selectedColumns[column] ?? leader}
                        onChange={(e) =>
                          setSelectedColumns((prev) => ({
                            ...prev,
                            [column]: e.target.value,
                          }))
                        }
                      >
                        <option value="" disabled>...</option>
                        {leaderArray.slice(0, aviableColumns[column]).map((leaderName, index) => (
                          <option key={index} value={index}>{leaderName}</option>
                        ))}
                      </select>
                    </div>
                  ))
                ) : (
                  <span>No hay columnas que coincidan</span>
                )}
              </div>
            </label>
          </div>

          {/* Display of chosed columns */}
          <div className="chosen-columns">
            <b>{Object.keys(selectedColumns).length > 0 
              ? `Columnas elegidas (${Object.keys(selectedColumns).length}):` 
              : "Columnas elegidas:"}
            </b>
            <span>
              {Object.keys(selectedColumns).length > 0 
                ? Object.keys(selectedColumns).map(column => `P${Object.keys(aviableColumns).indexOf(column) + 1}`).join("\t") 
                : ""}
            </span>
          </div>

          {/* Combination size */}
          <div>
            <label>
              <b>Tamaño</b>
              <input
                type="number"
                min="1"
                max={Object.keys(selectedColumns).length || 20}
                value={size}
                onChange={(e) => setSize(e.target.value)}
                placeholder="Tamaño combinacion"
              />
            </label>
          </div>

          <div className="button-group">
            <Button type="button" text="Cancelar" onClick={() => onFormClose(false)} hoverColor="#ffdddd" />
            <Button type="submit" text="Guardar" hoverColor="#ddffdd" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterGroupCombinationForm;
