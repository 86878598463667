import React, { useState, useEffect, useCallback } from "react";
import DropDown from "./DropDown";
import FilterManager from "./FilterManager";
import GroupManager from "./GroupManager";
import HandleExcelChoice from "./forms/DisplayTable/HandleExcelChoice";
import { handleCatch } from "../utils/handleCatch";
import { fetchData } from "../services/displayDataService";
import "../css/modal.css";
import "../css/DisplayTable.css";

const DisplayTable = ({ table, active, onUpdateLoading }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({});
  const [excelForm, setExcelForm] = useState(false);

  // Updates filter list with children selected checkboxes
  const updateFilterList = (childId, selectedCheckboxes) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      if (selectedCheckboxes) {
        currentFilters[childId] = selectedCheckboxes;
      } else {
        delete currentFilters[childId];
      }
      return currentFilters;
    });
  }

  // Gets base table without any filters
  const handleFetchData = useCallback(async () => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await fetchData();
      setData(data);
      setMeta(meta);
      setFilters({});
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  }, [onUpdateLoading]);

  // Only execute when rendering component - Loads base table
  useEffect(() => {
    if (active) handleFetchData();
  }, [active, handleFetchData]);

  // ---------- //
  return (
    <div className="container">
      <div className="row">
        <div className="column">
          <FilterManager
            filters={filters}
            setData={setData}
            setMeta={setMeta}
            setFilters={setFilters}
            onReset={handleFetchData}
            onUpdateLoading={onUpdateLoading}
            />
        </div>
        <div className="column">
          <GroupManager
            setData={setData}
            setMeta={setMeta}
            onReset={handleFetchData}
            onUpdateLoading={onUpdateLoading}
          />
        </div>
      </div>

      {data && data.length !== 0 ? (
        <div className="historial-section">
          <h2>{table}</h2>
          <label>({meta} lineas)</label>
          <button onClick={() => setExcelForm(true)}>Exportar</button>
          <br></br>
          <br></br>
          <div className="table-div">
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key, index) => (
                    <th key={key}>
                      <DropDown
                        columnName={key}
                        {...(index >= 6 && { columnKey: `P${index - 5}` })}
                        checkboxes={filters[key]}
                        onUpdateFilter={updateFilterList}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>No hay datos para mostrar</p>
      )}

    {excelForm && (
      <HandleExcelChoice 
        setExcelForm={setExcelForm}
        meta={meta}
        filters={filters}
        onUpdateLoading={onUpdateLoading}
      />
    )}
    </div>
  );
};

export default DisplayTable;
