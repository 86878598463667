export const handleResponse = async (response) => {
  if (response.ok) {
    const contentLength = response.headers.get("Content-Length");
    if (contentLength && parseInt(contentLength) > 0) return response.json();
    return null;
  }

  switch (response.status) {
    case 400:
      throw new Error("Bad request: Please check your input");
    case 401:
      throw new Error("Unauthorized: Please log in again");
    case 404:
      throw new Error("Resource not found");
    case 500:
      throw new Error("Server error: Please try again later");
    default:
      throw new Error(`Failed to perform operation: ${response.statusText}`);
  }
};