import React, { useEffect, useState, useCallback } from "react";
import { fetchFilters } from "../services/filterService";
import { fetchGroups } from "../services/filterGroupService";
import { filterTable } from "../services/displayDataService";
import { handleCatch } from "../utils/handleCatch";

import Filter from "./Filter";
import FilterGroup from "./FilterGroup";
import Button from "./Button";

import SaveFiltersForm from "./forms/FilterList/SaveFiltersForm";
import SaveDrawForm from "./forms/FilterList/SaveDrawForm";
import FilterGroupCombinationForm from "./forms/FilterList/FilterGroupCombinationForm";
import ExecuteProcedureForm from "./forms/FilterList/ExecuteProcedureForm";
import SaveGroupForm from "./forms/FilterList/SaveGroupForm";

const FiltersList = ({
  filters,
  setData,
  setMeta,
  setFilters,
  onReset,
  onUpdateLoading,
}) => {
  const [filterList, setFilterList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  // forms
  const [isFilterFormActive, setIsFilterFormActive] = useState(false);
  const [isGroupFormActive, setIsGroupFormActive] = useState(false);
  const [isDrawFormActive, setIsDrawFormActive] = useState(false);
  const [isFilterGroupCombinationFormActive, setIsFilterGroupCombinationFormActive] = useState(false);
  const [isProcedureFormActive, setIsProcedureFormActive] = useState(false);

  // Aplies a filter without modifying the table
  const handleFilterTable = async () => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await filterTable(filters);
      setData(data);
      setMeta(meta);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  };

  // Gets all filters and groups stored in the db
  const fetchData = useCallback(async () => {
    try {
      const [filterData, groupData] = await Promise.all([
        fetchFilters(),
        fetchGroups(),
      ]);
      setFilterList(filterData);
      setGroupList(groupData);
    } 
    catch (err) { handleCatch(err); }
  }, []);

  // Obtains data when rendering component
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // ------------------------- Component ------------------------- //
  return (
    <div>
      <h2>Filtros</h2>
      <div style={{ maxHeight: "30rem", overflow: "auto" }}>
        {/* Filters */}
        {filterList.map(({ filterID, name, type }, index) => (
          <div key={index}>
            <Filter 
              id={filterID} name={name} type={type}
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              setIsFilterFormActive={setIsFilterFormActive}
              onFilterChange={fetchData}
            />
          </div>
        ))}

        {/* Groups */}
        {groupList.map(({ groupID, name, count, type }, index) => (
          <div key={index}>
            <FilterGroup 
              id={groupID} name={name} count={count} type={type}
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              setIsGroupFormActive={setIsGroupFormActive}
              setIsFilterFormActive={setIsFilterFormActive}
              onFetchData={fetchData}
            />
          </div>
        ))}
      </div>

      <br></br>
      <Button text={"+ Filtro"} onClick={() => setIsFilterFormActive({ id: null })} hoverColor="#ddffdd"/>
      <Button text={"+ Sorteo"} onClick={() => setIsDrawFormActive(true)} hoverColor="#ddffdd"/>
      <Button text={"+ Grupo"} onClick={() => setIsGroupFormActive({ id: null })} hoverColor="#ddffdd"/>
      <Button text={"+ Combinacion"} onClick={() => setIsFilterGroupCombinationFormActive(true)} hoverColor="#ddffdd"/><br></br>
      <Button text={"➤ Procedimiento"} onClick={() => setIsProcedureFormActive(true)} hoverColor="#d0e7ff"/>
      <Button text={"Filtrar"} onClick={handleFilterTable} hoverColor="#d0e7ff"/>
      <Button text={"Reset"} onClick={onReset} hoverColor="#d0e7ff"/>

      {isFilterFormActive && (
        <SaveFiltersForm
          filters={filters}
          updateForm={isFilterFormActive.id}
          onFormClose={(wasItemCreated) => {
            setIsFilterFormActive(false);
            if (wasItemCreated) fetchData();
          }}
        />
      )}

      {isDrawFormActive && (
        <SaveDrawForm 
          onFormClose={(wasItemCreated) => {
            setIsDrawFormActive(false);
            if (wasItemCreated) fetchData();
          }}
        />
      )}

      {isFilterGroupCombinationFormActive && (
        <FilterGroupCombinationForm 
          onFormClose={(wasItemCreated) => {
            setIsFilterGroupCombinationFormActive(false);
            if (wasItemCreated) fetchData();
          }}
        />
      )}

      {isProcedureFormActive && (
        <ExecuteProcedureForm 
          onFormClose={(wasItemCreated) => {
            setIsProcedureFormActive(false);
            if (wasItemCreated) onReset();
          }}
          onUpdateLoading={onUpdateLoading}
        />
      )}

      {isGroupFormActive && (
        <SaveGroupForm 
          updateForm={isGroupFormActive.id}
          onFormClose={(wasItemCreated) => {
            setIsGroupFormActive(false);
            if (wasItemCreated) fetchData();
          }}
        />
      )}
    </div>
  );
};

export default FiltersList;
