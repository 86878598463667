import React, { useState } from "react";
import "../../css/Forms.css";

const GroupsForm = ({
  formData,
  setFormData,
  setIsFormActive,
  onSave,
  onUpdate,
}) => {
  const [name, setName] = useState(formData ? formData.name : "");
  const [group, setGroup] = useState(
    formData ? formData.group_data.join(",") : ""
  );
  const [quantity, setQuantity] = useState(
    formData ? formData.quantity.join(",") : ""
  );

  // Transforms form data into correct values and calls parent save function
  function saveGroup(e) {
    e.preventDefault();
    const numbersArray = group
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num !== "")
      .map(Number);
    const numberOfCombinations = quantity
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num !== "")
      .map(Number);

    if (!numbersArray.length || !numberOfCombinations.length || !name) {
      alert("Por favor introduce los números y la cantidad de combinaciones.");
      return;
    }

    const newGroup = {
      name: name,
      quantity: numberOfCombinations,
      group: numbersArray,
    };

    onSave(newGroup);
  }

  // Transforms form data into correct values and calls parent update function
  function updateGroup(e) {
    e.preventDefault();
    const numbersArray = group
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num !== "")
      .map(Number);
    const numberOfCombinations = quantity
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num !== "")
      .map(Number);

    if (!numbersArray.length || !numberOfCombinations || !name) {
      alert("Por favor introduce los números y la cantidad de combinaciones.");
      return;
    }

    const updateGroup = {
      name: name,
      quantity: numberOfCombinations,
      group: numbersArray,
    };

    onUpdate(formData.group_id, updateGroup);
  }

  // Resets form
  function resetForm() {
    setFormData(null);
    setIsFormActive(false);
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={formData ? updateGroup : saveGroup}>
          <div>
            <label>
              <b>Nombre del grupo</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>
          <div>
            <label>
              <b>¿Cuántos a coincidir? (separados por coma)</b>
              <input
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Ejemplo: 1,3,4"
                max={5}
              />
            </label>
          </div>
          <div>
            <label>
              <b>Introduce grupo de números (separados por coma):</b>
              <input
                type="text"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                placeholder="Ejemplo: 1,5,21, ... ,49"
              />
            </label>
          </div>
          <div className="button-group">
            <button type="button" onClick={resetForm}>
              Cancelar
            </button>
            <button type="submit">{formData ? "Modificar" : "Guardar"}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GroupsForm;
