import React, { useEffect, useState, useCallback } from "react";
import GroupsForm from "./forms/GroupsForm";

const URL = process.env.REACT_APP_API_URL;

const GroupsList = ({
  tableName,
  onUpdateLoading,
  onReset,
  setData,
  setMeta,
}) => {
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isFormDataActive, setIsFormActive] = useState(false);

  // Renders form with save or update functionality
  async function handleForm(id) {
    if (id) {
      try {
        const groupData = await getGroup(id);
        setFormData(groupData);
      } catch (err) {
        console.log("Error to update form ", err);
      }
    }
    setIsFormActive(true);
  }

  // Gets data from a group. Only to fill form data
  async function getGroup(id) {
    try {
      const response = await fetch(`${URL}/groups/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to get group");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error getting group: ", error);
      return null;
    } 
  }

  // Updates a group from the database
  async function updateGroup(id, formData) {
    try {
      const response = await fetch(`${URL}/groups/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to update group");
      }
      console.log("Group updated successfully");
    } catch (error) {
      console.log("Error updating group: ", error);
    } finally {
      fetchGroups();
      setIsFormActive(false);
      setFormData(null);
    }
  }

  // Saves a new group into the database
  async function saveGroup(formData) {
    try {
      const response = await fetch(`${URL}/groups/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to save group");
      }
      console.log("Group saved successfully");
    } catch (error) {
      console.log("Error saving group: ", error);
    } finally {
      fetchGroups();
      setIsFormActive(false);
      setFormData(null);
    }
  }

  // Deletes a group from the database
  async function deleteGroup(id) {
    try {
      const response = await fetch(`${URL}/groups/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete group");
      }
      fetchGroups();
      console.log("Group deleted successfully");
    } catch (error) {
      console.log("Error deleting group: ", error);
    }
  }

  // Deletes coincidences from current data and filter data
  async function removeGroup(id) {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/groups/remove/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ table: tableName }),
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error removing group: ", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Returns coincidences from current data and filter data
  async function applyGroup(id) {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/groups/apply/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ table: tableName }),
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error applying group: ", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Aplies a group to the current table, without modifying it (no delete rows or similar)
  async function showGroup(id) {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/groups/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ table: tableName }),
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error fetching groups: ", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Loads all groups from the db
  const fetchGroups = useCallback(async () => {
    try {
      const response = await fetch(`${URL}/groups/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch groups");
      }
      const data = await response.json();
      setGroups(data);
    } catch (error) {
      console.log("Error fetching groups: ", error);
    }
  }, []);

  // Update component on render
  useEffect(() => {
    fetchGroups(); // eslint-disable-next-line
  }, []);

  // ---------- //
  return (
    <div>
      <h2>Grupos</h2>
      <div>
        {groups.length > 0 && (
          <div>
            {groups.map((group, index) => (
              <div key={index}>
                <label>{group.name}</label>
                <button onClick={() => showGroup(group.id)}>Ver</button>
                <button onClick={() => applyGroup(group.id)}>Aplicar</button>
                <button onClick={() => removeGroup(group.id)}>Quitar</button>
                <button onClick={() => handleForm(group.id)}>Modificar</button>
                <button onClick={() => deleteGroup(group.id)}>Borrar</button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <button onClick={() => handleForm(null)}>Nuevo grupo</button>
        <br></br>
        <button onClick={onReset}>Reset</button>
      </div>

      {isFormDataActive && (
        <GroupsForm
          formData={formData}
          setFormData={setFormData}
          setIsFormActive={setIsFormActive}
          onSave={saveGroup}
          onUpdate={updateGroup}
        />
      )}
    </div>
  );
};

export default GroupsList;
