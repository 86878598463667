import { handleResponse } from "../utils/handleResponse";

const URL = process.env.REACT_APP_API_URL;

const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export const saveFilter = async (filterData) => {
  const response = await fetch(`${URL}/filters/save`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filterData),
  });
  return handleResponse(response);
}

export const saveDraw = async (filterData) => {
  const response = await fetch(`${URL}/filters/save_draw`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filterData),
  });
  return handleResponse(response);
}

export const saveFilterGroup = async (filterGroupData) => {
  const response = await fetch(`${URL}/filters/save_group`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filterGroupData),
  });
  return handleResponse(response);
}

export const updateFilter = async (filterData) => {
  const response = await fetch(`${URL}/filters/update`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(filterData),
  });
  return handleResponse(response);
}

export const deleteFilter = async (id) => {
  const response = await fetch(`${URL}/filters/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  });
  return handleResponse(response);
  }

export const getFilter = async (id) => {
  const response = await fetch(`${URL}/filters/${id}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const applyFilter = async (id) => {
  const response = await fetch(`${URL}/filters/apply/${id}`, {
    method: "POST",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const removeFilter = async (id) => {
  const response = await fetch(`${URL}/filters/remove/${id}`, {
    method: "POST",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const fetchFilters = async () => {
  const response = await fetch(`${URL}/filters/all`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const fetchGroupFilters = async (groupId) => {
  const response = await fetch(`${URL}/filters/all/${groupId}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}