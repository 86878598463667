import { handleResponse } from "../utils/handleResponse";

const URL = process.env.REACT_APP_API_URL;

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
}

export const saveGroup = async (groupData) => {
    const response = await fetch(`${URL}/groups/save`, {
      method: "POST",
      headers,
      body: JSON.stringify(groupData),
    });
    return handleResponse(response);
}

export const updateGroup = async (groupData) => {
  const response = await fetch(`${URL}/groups/update`, {
    method: "PUT",
    headers,
    body: JSON.stringify(groupData),
  });
  return handleResponse(response);
}

export const deleteGroup = async (id) => {
  const response = await fetch(`${URL}/groups/${id}`, {
    method: "DELETE",
    headers,
  });
  return handleResponse(response);
}

export const getGroup = async (id) => {
  const response = await fetch(`${URL}/groups/${id}`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
}

export const applyGroup = async (id) => {
  const response = await fetch(`${URL}/groups/apply/${id}`, {
    method: "POST",
    headers,
  });
  return handleResponse(response);
}

export const removeGroup = async (id) => {
  const response = await fetch(`${URL}/groups/remove/${id}`, {
    method: "POST",
    headers,
  });
  return handleResponse(response);
}

export const showGroup = async (id) => {
    const response = await fetch(`${URL}/groups/show/${id}`, {
      method: "POST",
      headers,
    });
    return handleResponse(response);
}

export const fetchGroups = async () => {
  const response = await fetch(`${URL}/groups/all`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
}