import React, { useState, useEffect } from "react";
import "../css/DropDown.css";

const URL = process.env.REACT_APP_API_URL;

const DropDown = ({ tableName, columnName, checkboxes, onUpdateFilter }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Updates selected checboxes here and in the parent
  function handleCheckboxChange(checkboxValue) {
    const newSelectedCheckboxes = selectedCheckboxes.includes(checkboxValue)
      ? selectedCheckboxes.filter((item) => item !== checkboxValue)
      : [...selectedCheckboxes, checkboxValue];

    setSelectedCheckboxes(newSelectedCheckboxes);
    onUpdateFilter(columnName, newSelectedCheckboxes);
  }

  // Select | Deselect all checkboxes
  function handleAllCheckboxChange(event) {
    if (event.target.checked) {
      const newOptions = dropdownOptions.map((option) => option.name);
      setSelectedCheckboxes(newOptions);
      onUpdateFilter(columnName, newOptions);
    } else {
      setSelectedCheckboxes([]);
      onUpdateFilter(columnName, []);
    }
  }

  // Updates component when parent changes props
  useEffect(() => {
    setSelectedCheckboxes(checkboxes);
    setIsOpen(checkboxes.length > 0);
  }, [checkboxes]);

  // Gets column data when opening the dropdown
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `${URL}/tables/${tableName}/${columnName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        setDropdownOptions(data);
      } catch (error) {
        console.error("Error fetching options: ", error);
      }
    };

    if (isOpen) fetchOptions(); // eslint-disable-next-line
  }, [isOpen]);

  // ---------- //
  return (
    <div className="dropdown-main">
      <div>
        <label
          className="dropdown-title"
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        >
          {columnName}
        </label>
      </div>
      {isOpen && (
        <div>
          <div className="dropdown-all">
            <label htmlFor={`select-all-${columnName}`}>Todo</label>
            <input
              type="checkbox"
              id={`select-all-${columnName}`}
              onChange={handleAllCheckboxChange}
              checked={
                selectedCheckboxes.length === dropdownOptions.length &&
                dropdownOptions.length > 0
              }
            />
          </div>
          {dropdownOptions.map((column, index) => (
            <div className="dropdown-checkboxes" key={index}>
              <label htmlFor={`checkbox-${columnName}-${index}`}>
                ({column.count})
              </label>
              <label htmlFor={`checkbox-${columnName}-${index}`}>
                {column.name}
              </label>
              <input
                type="checkbox"
                id={`checkbox-${columnName}-${index}`}
                value={column.name}
                onChange={() => handleCheckboxChange(column.name)}
                checked={selectedCheckboxes.includes(column.name)}
              />
            </div>
          ))}
          <div className="confirm"></div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
