import { useState } from "react";
import "../../css/Forms.css";

const FiltersForm = ({
  filters,
  groupList,
  formData,
  setFormData,
  setIsFormActive,
  onSave,
  onUpdate,
}) => {
  const [name, setName] = useState(formData ? formData.name : "");
  const [selectedGroup, setSelectedGroup] = useState(formData ? formData.groupId : null);
  const [rememberFilter, setRememberFilter] = useState(true);

  function handleGroupChange(e) {
    const value = e.target.value;
    setSelectedGroup(value === "" ? null : Number(value));
  }

  // Stores a new filter into the db
  async function saveFilter(e) {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del filtro.");
      return;
    }

    const newFilter = {
      name: name,
      filters: filters,
      groupId: selectedGroup,
    };

    onSave(newFilter);
  }

  // Updates a filter with the current filter data
  async function updateFilter(e) {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del filtro.");
      return;
    }
    
    const updateFilter = {
      name: name,
      filters: rememberFilter ? formData.filters : filters,
      groupId: selectedGroup,
    };

    onUpdate(formData.filterId, updateFilter);
  }

  // Resets form
  function resetForm() {
    setFormData(null);
    setIsFormActive(false);
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={formData ? updateFilter : saveFilter}>
          <div>
            <label>
              <b>Nombre del filtro</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>

          <div>
            <label>
              <b>Seleccionar grupo</b>
              <select
                value={selectedGroup === null ? "Sin grupo" : selectedGroup}
                onChange={handleGroupChange}
              >
                <option value="">Sin grupo</option>
                {groupList.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div>
            <label style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
              <b>Mantener filtro</b>
              <input
                style={{ width: "auto" }}
                type="checkbox"
                id="remember-filters"
                value={rememberFilter}
                onChange={() => setRememberFilter(!rememberFilter)}
                checked={rememberFilter}
              />
            </label>
          </div>
          
          <div className="button-group">
            <button type="button" onClick={resetForm}>
              Cancelar
            </button>
            <button type="submit">{formData ? "Modificar" : "Guardar"}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiltersForm;
