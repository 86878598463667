import { useState } from "react";
import "../../../css/Forms.css";
import { exportExcel, exportDraw } from "../../../services/exportService";
import { handleCatch } from "../../../utils/handleCatch";


const HandleExcelChoice = ({ setExcelForm, meta, filters, onUpdateLoading }) => {
  const [draw, setDraw] = useState([]);
  const [isExportFilterActive, setisExportFilterActive] = useState(false);

  // Updates the draw
  const handleSetDrawChange = (index, value) => {
    const updatedSetDraw = [...draw];
    updatedSetDraw[index] = value ? parseInt(value, 10) : "";
    setDraw(updatedSetDraw);
  }

  // Requests backend to generate a xsls and downloads it
  const handleExportExcel = async () => {
    if (meta >= 1000000) {
      alert("El maximo de filas de un excel son 1 millon");
      return;
    }
    onUpdateLoading(true);
    setExcelForm(false);
    try {
      const excelData = await exportExcel(filters);
      const blob = await excelData.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `Exported_Excel.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  }

  // Handles form data and prepares info for backend
  const handleExportDraw = async () => {
    if (draw.some((value) => !value)) {
      alert("Por favor, introduce todas las combinaciones.");
      return;
    }

    const drawToExport = {}
    const sortedDraw = draw.sort((a, b) => a - b);
    sortedDraw.forEach((element, index) => {
      drawToExport[`n${index + 1}`] = element;
    });

    submitExportDraw(drawToExport);
}

  // Generates a excel with data of a certain draw
  const submitExportDraw = async (draw) => {
    onUpdateLoading(true);
    setExcelForm(false);

    try {
      const excelData = await exportDraw(draw);
      const blob = await excelData.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `Exported_Filter.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
}

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* If exporting a filter, update componente to show draw input */}
        {isExportFilterActive ? (
          <div>
            <span>
              <b>Combinacion del sorteo</b>
            </span>
            <div className="set-draw-inputs">
              {[...Array(5)].map((_, index) => (
                <input
                  key={index}
                  type="number"
                  min="1"
                  max="50"
                  value={draw[index] || ""}
                  onChange={(e) => handleSetDrawChange(index, e.target.value)}
                  placeholder={`N${index + 1}`}
                />
              ))}
            </div>
            <div className="button-group">
              <button onClick={() => setExcelForm(false)}>Cancelar</button>
              <button onClick={handleExportDraw}>Ejecutar</button>
            </div>
          </div>
        ) : (
          /* Base component */
          <div>
            <div className="modal-title">
              <span>¿Que exportar?</span>
            </div>
            <div className="button-group">
              <button onClick={handleExportExcel}>Excel</button>
              <button onClick={() => setisExportFilterActive(true)}>Filtro</button>
            </div>
            <button onClick={() => setExcelForm(false)}>Cancelar</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HandleExcelChoice;
