import React, { useState } from "react";

const Button = ({ text, onClick, activeColor }) => {
  const [backgroundColor, setBackgroundColor] = useState("#F0F0F0");

  const handleClick = () => {
    if (activeColor) {
      setBackgroundColor(backgroundColor === "#F0F0F0" ? activeColor : "#F0F0F0");
    }
    onClick();
  }

  const style = {
    color: "black",
    backgroundColor: backgroundColor,
    margin: "0.1rem",
    padding: "0.2rem 0.4rem",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "0.8rem",
  };

  return (
    <button style={style} onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;
