import { useEffect, useState, useCallback, useMemo } from "react";
import "../../../css/Forms.css";
import { handleCatch } from "../../../utils/handleCatch";
import { fetchFilters } from "../../../services/filterService";
import { fetchGroups } from "../../../services/filterGroupService";

const ExecuteProcedureForm = ({
  setIsProcedureFormActive,
  onExecute,
}) => {
  const [filters, setFilters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});
  const [searchTermFilters, setSearchTermFilters] = useState("");
  const [searchTermGroups, setSearchTermGroups] = useState("");

  // Add or remove an item from the action list. If there is no item add it. 
  // if there is an item with different action change current action. If it has same action delete it
  function toggleAction(action, type, item) {
    setActionList((prev) => {
      const id = type === "filter" ? item.filterID : item.groupID;
      const exists = prev.find((entry) => 
        (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type  
      );

      if (!exists) return [...prev, { ...item, action, type }];

      else if (exists.action !== action) {
        return prev.map((entry) => 
          (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type 
            ? { ...entry, action } 
            : entry);
      } 
      
      else {
        return prev.filter((entry) => 
          !((type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type));
      }
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      const id = type === "filter" ? item.filterID : item.groupID;
      return {
        ...prev,
        [id]: prev[id] === (action === 'apply' ? 'rgb(0, 150, 0)' : 'red') ? null : action === 'apply' ? 'rgb(0, 150, 0)' : 'red',
      };
    });
  }

  // Add or remove or update all items from the action list.
  function toggleAll(action, type, items) {
    setActionList((prev) => {
      const updatedList = [...prev];
      items.forEach((item) => {
        const id = type === "filter" ? item.filterID : item.groupID;
        const exists = prev.find((entry) => 
          (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
        );

        if (!exists) {
          updatedList.push({ ...item, action, type });
        } 

        else if (exists.action !== action) {
          const index = updatedList.findIndex(
            (entry) => (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
          );
          updatedList[index] = { ...exists, action };
        } 
        
        else {
          const index = updatedList.findIndex(
            (entry) => (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
          );
          updatedList.splice(index, 1);
        }
      });
      return updatedList;
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      const updatedButtons = { ...prev };
      items.forEach((item) => {
        const id = type === "filter" ? item.filterID : item.groupID;
        updatedButtons[id] =
          updatedButtons[id] === (action === "apply" ? "rgb(0, 150, 0)" : "red")
            ? null
            : action === "apply"
            ? "rgb(0, 150, 0)"
            : "red";
      });
      return updatedButtons;
    });
  }

  // Executes procedure
  async function executeProcedure(e) {
    e.preventDefault();
    onExecute(actionList);
    setIsProcedureFormActive(false);
  }

  // Filters list of filters of the component
  const filteredFilters = useMemo(() => {
    return filters.filter((item) =>
      item.name.toLowerCase().includes(searchTermFilters.toLowerCase())
    );
  }, [filters, searchTermFilters]);

  // Filters list of groups of the component
  const filteredGroups = useMemo(() => {
    return groups.filter((item) =>
      item.name.toLowerCase().includes(searchTermGroups.toLowerCase())
    );
  }, [groups, searchTermGroups]);
  
  // Fetch data for filters
  const fetchData = useCallback(async () => {
    try {
      const [filterData, groupData] = await Promise.all([
        fetchFilters(),
        fetchGroups(),
      ]);
      setFilters(filterData);

      const sortedGroupData = groupData.sort((a, b) => a.count - b.count);
      setGroups(sortedGroupData);
    } 
    
    catch (err) {
      handleCatch(err);
    }
  }, []);

  // Renders component
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={executeProcedure}>
          <div>
            <h3 className="modal-title">Filtros</h3>
            <input
              type="text"
              placeholder="Buscar..."
              value={searchTermFilters}
              onChange={(e) => setSearchTermFilters(e.target.value)}
              style={{ width: "auto" }}
            />
            <button type="button" onClick={() => toggleAll("apply", "filter", filters)} style={{ margin: "0.5rem" }}>
              Aplicar todos
            </button>
            <button type="button" onClick={() => toggleAll("remove", "filter", filters)} style={{ margin: "0.5rem" }}>
              Quitar todos
            </button>
            <div className="item-list">
              {filteredFilters.map((item) => {
                const { filterID, name } = item;
                return (
                  <div key={filterID} className="list-item checkbox-label">
                    <span><b>{name}</b></span>
                    <div>
                      <button 
                        type="button" onClick={() => toggleAction("apply", "filter", item)}
                        style={{margin:"0.1rem", backgroundColor: clickedButtons[filterID] === 'rgb(0, 150, 0)' ? 'rgb(0, 150, 0)' : ''}}>
                        Aplicar
                      </button>
                      <button 
                        type="button" onClick={() => toggleAction("remove", "filter", item)}
                        style={{margin:"0.1rem", backgroundColor: clickedButtons[filterID] === 'red' ? 'red' : ''}}>
                        Quitar
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div>
            <h3 className="modal-title">Grupos</h3>
            <input
              type="text"
              placeholder="Buscar..."
              value={searchTermGroups}
              onChange={(e) => setSearchTermGroups(e.target.value)}
              style={{ width: "auto" }}
            />
            <button type="button" onClick={() => toggleAll("apply", "group", groups)} style={{ margin: "0.5rem" }}>
              Aplicar todos
            </button>
            <button type="button" onClick={() => toggleAll("remove", "group", groups)} style={{ margin: "0.5rem" }}>
              Quitar todos
            </button>
            <div className="item-list">
              {filteredGroups.map((item) => {
                const { groupID, name, count } = item;
                return (
                  <div key={groupID} className="list-item checkbox-label">
                    <span><b>{name} ({count})</b></span>
                    <div>
                      <button 
                        type="button" onClick={() => toggleAction("apply", "group", item)}
                        style={{margin:"0.1rem", backgroundColor: clickedButtons[groupID] === 'rgb(0, 150, 0)' ? 'rgb(0, 150, 0)' : ''}}>
                        Aplicar
                      </button>
                      <button 
                        type="button" onClick={() => toggleAction("remove", "group", item)}
                        style={{margin:"0.1rem", backgroundColor: clickedButtons[groupID] === 'red' ? 'red' : ''}}>
                        Quitar
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="button-group">
            <button type="button" onClick={() => setIsProcedureFormActive(false)}>Cancelar</button>
            <button type="submit">Ejecutar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExecuteProcedureForm;
