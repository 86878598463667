import React from "react";
import { getFilter, deleteFilter, applyFilter, removeFilter } from "../services/filterService";
import { handleCatch } from "../utils/handleCatch";
import Button from "./Button";

/**
 * @param {Variable} id - The current Filter ID.
 * @param {Variable} name - The current Filter name.

 * DisplayTable.jsx
 * @param {Function} setData - Function to update rows on of the table.
 * @param {Function} setMeta - Function to update metadata of the table.
 * @param {Function} setFilters - Function to update the filter data.
 
 * FilterManager.jsx
 * @param {Function} onFetchData - Function to reload data after deleting a filter.
 * @param {Function} setFormData - Function to update the form data.
 * @param {Function} setIsFilterFormActive - Function for activating or deactivating the FilterForm.
 
 * Group.jsx
 * @param {Function} onFilterChange - Function for updating this filter Group's list.
 */
const Filter = ({ 
  id, name, 
  setFormData, setIsFilterFormActive, 
  setData, setMeta, setFilters, 
  onFilterChange,
 }) => {
  // Updates the columns from DisplayData table with the current Filters data
  const handleShowFilter = async () => {
    try {
      const currentFilterData = await getFilter(id);
      if (currentFilterData) setFilters(currentFilterData.filters);
    } 
    catch (err) { handleCatch(err); }
  }

  // Updates the table from DisplayData keeping entries that match the current Filter data
  const handleApplyFilter = async () => {
    try {
      const currentFilterData = await applyFilter(id);
      if (!currentFilterData) return;
      setData(currentFilterData.data);
      setMeta(currentFilterData.meta);
      setFilters({});
    } 
    catch (err) { handleCatch(err); }
  }

  // Updates the table from DisplayData deleting entries that match the current Filter data
  const handleRemoveFilter = async () => {
    try {
      const currentFilterData = await removeFilter(id);
      if (!currentFilterData) return;
      setData(currentFilterData.data);
      setMeta(currentFilterData.meta);
      setFilters({});
    } 
    catch (err) { handleCatch(err); }
  }

  // Fills the FilterForm with the current Filter data
  const handleUpdateFilter = async () => {
    try {
      const currentFilterData = await getFilter(id);
      setFormData(currentFilterData);
      setIsFilterFormActive(true);
    } 
    catch (err) { handleCatch(err); }
  }

  // Deletes the Filter from the db and updates FilterList
  const handleDeleteFilter = async () => {
    const confirm = window.confirm(`¿Borrar filtro ${name}?`)
    if (!confirm) return;
    try { 
      await deleteFilter(id) 
    } 
    catch (err) { handleCatch(err); }
    finally { onFilterChange(); }
  }

  return (
    <div>
      <span><b>{name}</b></span>
      <Button 
        text={"Ver"}
        onClick={handleShowFilter}
      />
      <Button 
        text={"Aplicar"}
        onClick={handleApplyFilter}
        activeColor="rgb(0, 150, 0)"
      />
      <Button 
        text={"Quitar"}
        onClick={handleRemoveFilter}
        activeColor="red"
      />
      <Button 
        text={"Modificar"}
        onClick={handleUpdateFilter}
      />
      <Button 
        text={"Borrar"}
        onClick={handleDeleteFilter}
      />
    </div>
  );
};

export default Filter;
