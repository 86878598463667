import React, { useState, useEffect, useCallback } from "react";
import DropDown from "./DropDown";
import FiltersList from "./FiltersList";
import GroupsList from "./GroupsList";
import "../css/modal.css";
import "../css/DisplayTable.css";

const URL = process.env.REACT_APP_API_URL;

const DisplayTable = ({ table, active, onUpdateLoading }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({});

  // Updates filter list with children selected checkboxes
  function updateFilterList(childName, selectedCheckboxes) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [childName]: selectedCheckboxes,
    }));
  }

  // Asks backend to generate a csv and downloads it
  async function exportExcel() {
    if (meta >= 1000000) {
      alert("El maximo de filas de un excel son 1 millon");
      return;
    }
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/export/excel/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        throw new Error("Error al exportar Excel");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `exported_${table}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el Excel:", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Gets base table without any filters
  const getTable = useCallback(async () => {
    try {
      onUpdateLoading(true);
      const response = await fetch(`${URL}/tables/${table}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setFilters({});
      onUpdateLoading(false);
    } // eslint-disable-next-line
  }, []);

  // Only execute when rendering component - Loads base table
  useEffect(() => {
    if (active) getTable(); // eslint-disable-next-line
  }, []);

  // ---------- //
  return (
    <div className="container">
      <div className="row">
        <div className="column">
          <div>
            <FiltersList
              table={table}
              filters={filters}
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              onReset={getTable}
              onUpdateLoading={onUpdateLoading}
            />
          </div>
        </div>
        <div className="column">
          <GroupsList
            tableName={table}
            onUpdateLoading={onUpdateLoading}
            setData={setData}
            setMeta={setMeta}
            onReset={getTable}
          />
        </div>
      </div>

      {data && data.length !== 0 ? (
        <div className="historial-section">
          <h2>{table}</h2>
          <label>({meta} lineas)</label>
          <button onClick={exportExcel}>Exportar</button>
          <br></br>
          <br></br>
          <div className="table-div">
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th key={key}>
                      <DropDown
                        tableName={table}
                        columnName={key}
                        checkboxes={filters[key] || []}
                        onUpdateFilter={updateFilterList}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>No hay datos para mostrar</p>
      )}
    </div>
  );
};

export default DisplayTable;
