import React, { useState, useEffect } from "react";

const Button = ({ 
  text, 
  onClick, 
  activeColor, 
  hoverColor,
  backgroundColor: propBackgroundColor,
  type = "", 
  className = "" 
}) => {
  const defaultColor = "#F0F0F0";
  const [backgroundColor, setBackgroundColor] = useState(propBackgroundColor || defaultColor);
  const [isActive, setIsActive] = useState(false);

  // When clicking the button
  const handleClick = () => {
    if (activeColor) {
      setIsActive(true);
      setBackgroundColor(activeColor);
    }
    if (onClick) onClick();
  };

  // When cursor over the button
  const handleMouseEnter = () => {
    if (!isActive && hoverColor) {
      setBackgroundColor(hoverColor);
    }
  };

  // When cursor leaves the button
  const handleMouseLeave = () => {
    if (!isActive) {
      setBackgroundColor(propBackgroundColor || defaultColor);
    }
  };

  // Updates the background color of the button
  useEffect(() => {
    setBackgroundColor(propBackgroundColor || defaultColor);
  }, [propBackgroundColor]);

  const style = {
    color: "black",
    backgroundColor,
    margin: "0.1rem",
    padding: "0.3rem 0.4rem",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "0.8rem",
    transition: hoverColor || activeColor ? "background-color 0.3s" : "none",
  };

  return (
    <button 
      className={className} 
      type={type} 
      style={style} 
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </button>
  );
};

export default Button;
