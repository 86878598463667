import React, { useState, useEffect, useCallback } from 'react'

import Filter from './Filter';
import Button from './Button';

import { fetchGroupFilters } from '../services/filterService';
import { deleteGroup, applyGroup, removeGroup } from '../services/filterGroupService';
import { handleCatch } from '../utils/handleCatch';

/**
 * @param {Variable} id - The current Filter ID.
 * @param {Variable} name - The current Filter name.
 * @param {Variable} count - Size of this group.
 * @param {Variable} type - Type of the group, apply, rmeove or non.

 * DisplayTable.jsx
 * @param {Function} setData - Function to update rows on of the table.
 * @param {Function} setMeta - Function to update metadata of the table.
 * @param {Function} setFilters - Function to update the filter data.
 
 * FilterManager.jsx
 * @param {Function} onFetchData - Function to reload data after deleting a filter.
 * @param {Function} setIsGroupFormActive - Function for activating or deactivating the GroupForm.
 * @param {Function} setIsFilterFormActive - Function for activating or deactivating the FilterForm.
 */
const FilterGroup = ({ 
  id, name, count, type, 
  onFetchData, setIsGroupFormActive, setIsFilterFormActive,
  setData, setMeta, setFilters, 
}) => {
  const [filterList, setFilterList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  // Updates the table from DisplayData keeping entries that match the current Filter data
  const handleApplyGroup = async () => {
    try {
      const currentGroupData = await applyGroup(id);
      if (!currentGroupData) return;
      setData(currentGroupData.data);
      setMeta(currentGroupData.meta);
      setFilters({});
    } 
    catch (err) { handleCatch(err); }
  }

  // Updates the table from DisplayData deleting entries that match the current Filter data
  const handleRemoveGroup = async () => {
    try {
      const currentGroupData = await removeGroup(id);
      if (!currentGroupData) return;
      setData(currentGroupData.data);
      setMeta(currentGroupData.meta);
      setFilters({});
    } 
    catch (err) { handleCatch(err); }
  }

  // Deletes the Group from the db and updates FilterList
  const handleDeleteGroup = async () => {
    const confirm = window.confirm(`¿Borrar grupo ${name}?`)
    if (!confirm) return;
    try { await deleteGroup(id) } 
    catch (err) { handleCatch(err); }
    finally { onFetchData(); }
  }

  // Obtains this Group's Filters when expanding
  const fetchGroupFiltersData = useCallback(async () => {
    try {
      const filterData = await fetchGroupFilters(id);
      setFilterList(filterData);
    } 
    catch (err) { handleCatch(err); }
  }, [id]);
  
  useEffect(() => {
    if (isExpanded) fetchGroupFiltersData();
  }, [isExpanded, fetchGroupFiltersData]);

  return (
    <div>
      <span onClick={() => setIsExpanded(!isExpanded)}>
        <b>{isExpanded ? "▼" : "▶"} {name} ({count})</b>
      </span>
      <Button  text={"Aplicar"} onClick={handleApplyGroup} activeColor="rgb(0, 170, 0)" hoverColor="#d0e7ff" />
      <Button  text={"Quitar"} onClick={handleRemoveGroup} activeColor="rgb(200, 50, 50)" hoverColor="#d0e7ff" />
      <Button  text={"Modificar"} onClick={() => setIsGroupFormActive({ id: id })} hoverColor="#d0e7ff" />
      <Button  text={"Borrar"} onClick={handleDeleteGroup} hoverColor="#ffdddd" />
      
      {/* When expanding the Group */}
      <div style={{maxHeight: "15rem", overflowY: "auto"}}>
        {isExpanded && filterList.map(({ filterID, name, type }, index) => (
          <div key={index} style={{ marginLeft: "1.5rem"}}>
            <Filter 
              id={filterID} name={name} type={type}
              setData={setData}
              setMeta={setMeta}
              setFilters={setFilters}
              setIsFilterFormActive={setIsFilterFormActive}
              onFilterChange={fetchGroupFiltersData}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterGroup
