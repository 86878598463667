import { useState, useEffect, useCallback } from "react";
import "../../../css/Forms.css";
import { saveFilter, updateFilter, getFilter } from "../../../services/filterService";
import { fetchGroups } from "../../../services/filterGroupService";
import { handleCatch } from "../../../utils/handleCatch";
import Button from "../../Button";

/**
 * FilterManager.jsx
 * @param {Variable} updateForm - If there is an id the form is for updating a group, if there is nothing its for saving a group
 * @param {Function} onFormClose - Function to close the form and update parents filter list if a draw (as a filter) was stored.
 */
const SaveFiltersForm = ({ filters, updateForm, onFormClose }) => {
  const [filterID, setFilterID] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [oldFilters, setOldFilters] = useState({})
  const [selectedGroup, setSelectedGroup] = useState("");
  const [rememberFilter, setRememberFilter] = useState(false);
  const [aviableGroups, setAviableGroups] = useState([]);

  // Uses form data to create a new filter
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del filtro.");
      return;
    }

    const newFilter = {
      name,
      filters : rememberFilter ? oldFilters : filters,
      groupID: selectedGroup ? Number(selectedGroup) : null,
      type: type ? type : null,
      filterID,
    };

    updateForm ? handleUpdateFilter(newFilter) : handleSaveFilter(newFilter);
  }

  // Stores a new filter into the db
  const handleSaveFilter = async (formData) => {
    try { await saveFilter(formData); } 
    catch (err) { handleCatch(err); } 
    finally { onFormClose(true); }
  }

  // Updates a filter in the db
  const handleUpdateFilter = async (formData) => {
    try { await updateFilter(formData); } 
    catch (err) { handleCatch(err); } 
    finally { onFormClose(true); }
  }

  // Fetch filter data
  const fetchFilterData = useCallback(async (filterID) => {
    try {
      const filterData = await getFilter(filterID);
      setFilterID(filterData.filterID);
      setName(filterData.name);
      setType(filterData.type);
      setSelectedGroup(filterData.groupID);
      setRememberFilter(true);
      setOldFilters(filterData.filters);
    } 
    catch (err) { handleCatch(err); }
  }, []);

  // Fetch group data
  const fetchGroupsData = useCallback(async () => {
    try { setAviableGroups(await fetchGroups()); }
    catch (err) { handleCatch(err); }
  }, []);
  
  // When rendering component, if update, loads filter data
  useEffect(() => {
    if (updateForm) {
      setFilterID(updateForm);
      fetchFilterData(updateForm);
    }
    fetchGroupsData();
  }, [updateForm, fetchFilterData, fetchGroupsData]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>
              <b>Nombre del filtro</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>

          <div>
            <label>
              <b>Seleccionar grupo</b>
              <select
                value={selectedGroup === "" ? "Sin grupo" : selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="">Sin grupo</option>
                {aviableGroups.map(({ groupID, name }) => (
                  <option key={groupID} value={groupID}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div>
            <label>
              <b>Tipo de filtro</b>
              <select
                value={type === "" ? "Sin tipo" : type}
                onChange={(e) => setType(e.target.value)}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin tipo</option>
                <option value="apply">Aplicar</option>
                <option value="remove">Quitar</option>
              </select>
            </label>
          </div>

          {updateForm && (
            <div>
              <label style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                <b>Mantener filtro</b>
                <input
                  style={{ width: "auto" }}
                  type="checkbox"
                  id="remember-filters"
                  value={rememberFilter}
                  onChange={() => setRememberFilter(!rememberFilter)}
                  checked={rememberFilter}
                />
              </label>
            </div>
          )}
          
          <div className="button-group">
            <Button type="button" text="Cancelar" onClick={() => onFormClose(false)} hoverColor="#ffdddd" />
            <Button type="submit" text={updateForm ? "Modificar" : "Guardar"} hoverColor="#ddffdd" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveFiltersForm;
