import { useEffect, useState, useCallback, useMemo } from "react";
import { handleCatch } from "../../../utils/handleCatch";
import { applyFilter, removeFilter } from "../../../services/filterService";
import { applyGroup, removeGroup } from "../../../services/filterGroupService";
import { fetchFilters } from "../../../services/filterService";
import { fetchGroups } from "../../../services/filterGroupService";
import Button from "../../Button";
import "../../../css/Forms.css";

/**
 * App.js
 * @param {Function} onSetLoading - Function to update parent state and display a modal that blocks user from interacting while working.
 
 * FilterManager.jsx
 * @param {Function} onFormClose - Function to close the form and update parents filter list if a draw (as a filter) was stored.
 */
const ExecuteProcedureForm = ({ onUpdateLoading, onFormClose }) => {
  const [filters, setFilters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [executionList, setExecutionList] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});
  const [searchTermFilters, setSearchTermFilters] = useState("");
  const [searchTermGroups, setSearchTermGroups] = useState("");

  // Add or remove an item from the action list. If there is no item add it. 
  // if there is an item with different action change current action. If it has same action delete it
  const toggleAction = (action, type, item) => {
    setExecutionList((prev) => {
      const id = type === "filter" ? item.filterID : item.groupID;
      const exists = prev.find((entry) => 
        (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type  
      );

      if (!exists) return [...prev, { ...item, action, type }];

      else if (exists.action !== action) {
        return prev.map((entry) => 
          (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type 
            ? { ...entry, action } 
            : entry);
      } 
      
      else {
        return prev.filter((entry) => 
          !((type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type));
      }
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      const id = type === "filter" ? item.filterID : item.groupID;
      return {
        ...prev,
        [id]: prev[id] === (action === 'apply' ? 'rgb(0, 170, 0)' : 'rgb(200, 50, 50)') ? null : action === 'apply' ? 'rgb(0, 170, 0)' : 'rgb(200, 50, 50)',
      };
    });
  }

  // Add or remove or update all items from the action list.
  const toggleAll = (action, type, items) => {
    setExecutionList((prev) => {
      const updatedList = [...prev];
      items.forEach((item) => {
        const id = type === "filter" ? item.filterID : item.groupID;
        const exists = prev.find((entry) => 
          (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
        );

        if (!exists) {
          updatedList.push({ ...item, action, type });
        } 

        else if (exists.action !== action) {
          const index = updatedList.findIndex(
            (entry) => (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
          );
          updatedList[index] = { ...exists, action };
        } 
        
        else {
          const index = updatedList.findIndex(
            (entry) => (type === "filter" ? entry.filterID : entry.groupID) === id && entry.type === type
          );
          updatedList.splice(index, 1);
        }
      });
      return updatedList;
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      const updatedButtons = { ...prev };
      items.forEach((item) => {
        const id = type === "filter" ? item.filterID : item.groupID;
        updatedButtons[id] =
          updatedButtons[id] === (action === "apply" ? "rgb(0, 170, 0)" : "rgb(200, 50, 50)")
            ? null
            : action === "apply"
            ? "rgb(0, 170, 0)"
            : "rgb(200, 50, 50)";
      });
      return updatedButtons;
    });
  }

  // Creates an execution procedure to apply/remove filters and groups in some order
  const executeProcedure = async (e) => {
    e.preventDefault();
    onUpdateLoading(true);
    
    const filters = executionList.filter((item) => item.type === 'filter');
    const groups = executionList.filter((item) => item.type === 'group');
    const sortedGroups = groups.sort((a, b) => a.count - b.count);

    // Combine and prioritize: first "apply" then "remove"
    const orderedFilters = [
      ...filters.filter((item) => item.action === 'apply'),
      ...filters.filter((item) => item.action === 'remove'),
    ];

    const orderedGroups = [
      ...sortedGroups.filter((item) => item.action === 'apply'),
      ...sortedGroups.filter((item) => item.action === 'remove'),
    ];

    try {
      // Process filters one by one
      for(const filter of orderedFilters) {
        const { filterID, action } = filter;
        action === "apply" 
          ? await applyFilter(filterID)
          : await removeFilter(filterID);
      }

      // Process groups one by one
      for (const group of orderedGroups) {
        const { groupID, action } = group;
        action === "apply" 
          ? await applyGroup(groupID)
          : await removeGroup(groupID);
      }
    }
    catch(err) { handleCatch(err); }
    finally { 
      onUpdateLoading(false);
      onFormClose(true);
     }
  }

  // Filters list of filters of the component
  const filteredFilters = useMemo(() => {
    return filters.filter((item) =>
      item.name.toLowerCase().includes(searchTermFilters.toLowerCase())
    );
  }, [filters, searchTermFilters]);

  // Filters list of groups of the component
  const filteredGroups = useMemo(() => {
    return groups.filter((item) =>
      item.name.toLowerCase().includes(searchTermGroups.toLowerCase())
    );
  }, [groups, searchTermGroups]);
  
  // Fetch data for filters
  const fetchData = useCallback(async () => {
    try {
      const [filterData, groupData] = await Promise.all([
        fetchFilters(),
        fetchGroups(),
      ]);
      setFilters(filterData);
  
      const sortedGroupData = groupData.sort((a, b) => a.count - b.count);
      setGroups(sortedGroupData);

      // Auto select filter with tag "type"
      const preselectedFilters = filterData
        .filter(filter => filter.type === "apply" || filter.type === "remove")
        .map(filter => ({
          ...filter,
          action: filter.type,
          type: "filter"
        }));

      setExecutionList(preselectedFilters);

      // Update clicked list to reflect preselected filters
      setClickedButtons(prev => {
        const updatedButtons = { ...prev };
        preselectedFilters.forEach(filter => {
          updatedButtons[filter.filterID] = filter.action === "apply" ? "rgb(0, 170, 0)" : "rgb(200, 50, 50)";
        });
        return updatedButtons;
      })
  
      // Auto select grups with tag "type"
      const preselectedGroups = groupData
        .filter(group => group.type === "apply" || group.type === "remove")
        .map(group => ({
          ...group,
          action: group.type,
          type: "group"
        }));
  
      setExecutionList(prev => [...prev, ...preselectedGroups]);
  
      // Update clicked list to reflect preselected groups
      setClickedButtons(prev => {
        const updatedButtons = { ...prev };
        preselectedGroups.forEach(group => {
          updatedButtons[group.groupID] = group.action === "apply" ? "rgb(0, 170, 0)" : "rgb(200, 50, 50)";
        });
        return updatedButtons;
      });
    } 
    catch (err) {
      handleCatch(err);
    }
  }, []);

  // Renders component
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={executeProcedure}>
          <div>
            <h3 className="modal-title">Filtros</h3>
            <input
              type="text"
              placeholder="Buscar..."
              value={searchTermFilters}
              onChange={(e) => setSearchTermFilters(e.target.value)}
              style={{ width: "auto" }}
            />
            <Button type="button" text="Aplicar todos" onClick={() => toggleAll("apply", "filter", filters)} hoverColor="#d0e7ff" />
            <Button type="button" text="Quitar todos" onClick={() => toggleAll("remove", "filter", filters)} hoverColor="#d0e7ff" />
            <div className="item-list">
              {filteredFilters.map((item) => {
                const { filterID, name } = item;
                return (
                  <div key={filterID} className="list-item checkbox-label">
                    <span><b>{name}</b></span>
                    <div>
                      <Button 
                        type="button" text="Aplicar" onClick={() => toggleAction("apply", "filter", item)} hoverColor="#d0e7ff" 
                        backgroundColor={clickedButtons[filterID] === 'rgb(0, 170, 0)' ? 'rgb(0, 170, 0)' : ''}
                      />
                      <Button 
                        type="button" text="Quitar" onClick={() => toggleAction("remove", "filter", item)} hoverColor="#d0e7ff" 
                        backgroundColor={clickedButtons[filterID] === 'rgb(200, 50, 50)' ? 'rgb(200, 50, 50)' : ''}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div>
            <h3 className="modal-title">Grupos</h3>
            <input
              type="text"
              placeholder="Buscar..."
              value={searchTermGroups}
              onChange={(e) => setSearchTermGroups(e.target.value)}
              style={{ width: "auto" }}
            />
            <Button type="button" text="Aplicar todos" onClick={() => toggleAll("apply", "group", groups)} hoverColor="#d0e7ff" />
            <Button type="button" text="Quitar todos" onClick={() => toggleAll("remove", "group", groups)} hoverColor="#d0e7ff" />
            <div className="item-list">
              {filteredGroups.map((item) => {
                const { groupID, name, count } = item;
                return (
                  <div key={groupID} className="list-item checkbox-label">
                    <span><b>{name} ({count})</b></span>
                    <div>
                      <Button 
                        type="button" text="Aplicar" onClick={() => toggleAction("apply", "group", item)} hoverColor="#d0e7ff" 
                        backgroundColor={clickedButtons[groupID] === 'rgb(0, 170, 0)' ? 'rgb(0, 170, 0)' : ''}
                      />
                      <Button 
                        type="button" text="Quitar" onClick={() => toggleAction("remove", "group", item)} hoverColor="#d0e7ff" 
                        backgroundColor={ clickedButtons[groupID] === 'rgb(200, 50, 50)' ? 'rgb(200, 50, 50)' : ''}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="button-group">
            <Button type="button" text="Cancelar" onClick={() => onFormClose(false)} hoverColor="#ffdddd" />
            <Button type="submit" text="Ejecutar" hoverColor="#d0e7ff" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExecuteProcedureForm;
