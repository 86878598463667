import { handleResponse } from "../utils/handleResponse";

const URL = process.env.REACT_APP_API_URL;

const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export const fetchData = async () => {
  const response = await fetch(`${URL}/tables/get_data`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const fetchColumnData = async (column) => {
  const response = await fetch(`${URL}/tables/${column}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}

export const filterTable = async (filterData) => {
  const response = await fetch(`${URL}/tables/filter`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filterData),
  });
  return handleResponse(response);
}

export const fetchColumns = async () => {
  const response = await fetch(`${URL}/tables/columns`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
}