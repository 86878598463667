import React, { useEffect, useState, useCallback } from "react";
import GroupsForm from "./forms/GroupsList/GroupsForm";
import { handleCatch } from "../utils/handleCatch";
import { getGroup, updateGroup, saveGroup, deleteGroup, applyGroup, removeGroup, fetchGroups, showGroup } from "../services/groupService";
import Button from "./Button";

/**
 * DisplayTable.jsx
 * @param {Function} setData - Function to update rows on of the table.
 * @param {Function} setMeta - Function to update metadata of the table.
 * @param {Function} onReset - Function reset temporal changes on the table.
 * @param {Function} onUpdateLoading - Function to trigger a popUp when loading data.
 */
const GroupsList = ({
  setData,
  setMeta,
  onReset,
  onUpdateLoading,
}) => {
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isFormDataActive, setIsFormActive] = useState(false);

  // Handle the initial form data
  const handleForm = async (id) => {
    if (id) {
      try {
        const groupData = await fetchGroup(id);
        setFormData(groupData);
      } catch (err) {
        console.log("Error to update form ", err);
      }
    }
    setIsFormActive(true);
  }

  // Obtains a group's data
  const fetchGroup = async (id) => {
    try {
      const groupData = await getGroup(id);
      return groupData;
    } 
    catch (err) {handleCatch(err);} 
  }

  // Updates a group with new data
  const handleUpdateGroup = async (formData) => {
    try {await updateGroup(formData);} 
    catch (err) {handleCatch(err);} 
    finally {
      handleFetchGroups();
      setIsFormActive(false);
      setFormData(null);
    }
  }

  // Saves a new group into the database
  const handleSaveGroup = async (formData) => {
    try { await saveGroup(formData) } 
    catch (err) { handleCatch(err); } 
    finally {
      handleFetchGroups();
      setIsFormActive(false);
      setFormData(null);
    }
  }

  // Deletes a group from the database
  const handleDeleteGroup = async (id) => {
    const confirmDelete = window.confirm(`Estas a punto de borrar el grupo ${
      groups.find((group) => group.groupID === id).name.toUpperCase()
    }`)
    if (!confirmDelete) return;
    try { await deleteGroup(id); } 
    catch (err) { handleCatch(err); }
    finally { handleFetchGroups(); }
  }

 // Updates the table from DisplayData deleting entries that match the current Filter data
  const handleRemoveGroup = async (id) => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await removeGroup(id);
      setData(data);
      setMeta(meta);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  }

  // Updates the table from DisplayData keeping entries that match the current Group data
  const handleApplyGroup = async (id) => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await applyGroup(id);
      setData(data);
      setMeta(meta);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  }

  // Updates the table from DisplayData keeping entries that match the current Group data without modifying table data
  const handleShowGroup = async (id) => {
    onUpdateLoading(true);
    try {
      const { data, meta } = await showGroup(id);
      setData(data);
      setMeta(meta);
    } 
    catch (err) { handleCatch(err); } 
    finally { onUpdateLoading(false); }
  }

  // Obtains all the Groups stored in the db
  const handleFetchGroups = useCallback(async () => {
    try {
      const groupsData = await fetchGroups();
      setGroups(groupsData);
    } 
    catch (err) { handleCatch(err); }
  }, []);

  // Update component on render
  useEffect(() => {
    handleFetchGroups();
  }, [handleFetchGroups]);

  // ---------- //
  return (
    <div>
      <h2>Grupos</h2>
      <div>
        {groups.length > 0 && (
          <div>
            {groups.map(({ groupID, name }, index) => (
              <div key={index}>
                <span><b>{name}</b></span>
                <Button 
                  text={"Ver"}
                  onClick={() => handleShowGroup(groupID)}
                />
                <Button 
                  text={"Aplicar"}
                  onClick={() => handleApplyGroup(groupID)}
                  activeColor="rgb(0, 150, 0)"
                />
                <Button 
                  text={"Quitar"}
                  onClick={() => handleRemoveGroup(groupID)}
                  activeColor="red"
                />
                <Button 
                  text={"Modificar"}
                  onClick={() => handleUpdateGroup(groupID)}
                />
                <Button 
                  text={"Borrar"}
                  onClick={() => handleDeleteGroup(groupID)}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <button onClick={() => handleForm(null)}>Nuevo grupo</button>
        <br></br>
        <button onClick={onReset}>Reset</button>
      </div>

      {isFormDataActive && (
        <GroupsForm
          formData={formData}
          setFormData={setFormData}
          setIsFormActive={setIsFormActive}
          onSave={handleSaveGroup}
          onUpdate={handleUpdateGroup}
        />
      )}
    </div>
  );
};

export default GroupsList;
