import { useState, useEffect, useCallback } from "react";
import { saveDraw } from "../../../services/filterService";
import { handleCatch } from "../../../utils/handleCatch";
import { fetchGroups } from "../../../services/filterGroupService";
import Button from "../../Button";
import "../../../css/Forms.css";

/**
 * FilterManager.jsx
 * @param {Function} onFormClose - Function to close the form and update parents filter list if a draw (as a filter) was stored.
 */
const SaveDrawForm = ({ onFormClose }) => {
  const [name, setName] = useState("");
  const [draw, setDraw] = useState([]);
  const [type, setType] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  // Updates the values of the Ns combination
  const handleSetDrawChange = (index, value) => {
    const updatedSetDraw = [...draw];
    updatedSetDraw[index] = value ? parseInt(value, 10) : "";
    setDraw(updatedSetDraw);
  }

  // Stores a new filter into the db
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del filtro.");
      return;
    } if (draw.some((value) => !value)) {
      alert("Por favor, introduce todas las combinaciones.");
      return;
    }

    const newFilter = {
      name,
      groupID: selectedGroup ? Number(selectedGroup) : null,
      type: type ? type : null,
    };
    const sortedDraw = draw.sort((a, b) => a - b);
    sortedDraw.forEach((element, index) => {
      newFilter[`n${index + 1}`] = element;
    });

    handleSaveDraw(newFilter);
  }

  // Saves a new filter with a specific combination data
  const handleSaveDraw = async (formData) => {
    try { await saveDraw(formData); } 
    catch (err) { handleCatch(err); }
    finally { onFormClose(true); }
  }

  // Fetch aviable columns when rendering the component
  const fetchAllGroups = useCallback(async () => {
      try {
        const groups = await fetchGroups();
        setGroupList(groups);
      } 
      catch (err) { handleCatch(err); }
    }, []);

  useEffect(() => {
    fetchAllGroups();
  }, [fetchAllGroups]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>
              <b>Nombre del filtro</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>

          <div>
            <label>
              <b>Combinacion del sorteo</b>
            </label>
            <div className="set-draw-inputs">
              {[...Array(5)].map((_, index) => (
                <input
                  key={index}
                  type="number"
                  min="1"
                  max="50"
                  value={draw[index] || ""}
                  onChange={(e) => handleSetDrawChange(index, e.target.value)}
                  placeholder={`N${index + 1}`}
                />
              ))}
            </div>
          </div>

          <div>
            <label>
              <b>Seleccionar grupo</b>
              <select
                value={selectedGroup === null ? "Sin grupo" : selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin grupo</option>
                {groupList.map(({ groupID, name }) => (
                  <option key={groupID} value={groupID}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div>
            <label>
              <b>Tipo de filtro</b>
              <select
                value={type === "" ? "Sin tipo" : type}
                onChange={(e) => setType(e.target.value)}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin tipo</option>
                <option value="apply">Aplicar</option>
                <option value="remove">Quitar</option>
              </select>
            </label>
          </div>

          <div className="button-group">
            <Button type="button" text="Cancelar" onClick={() => onFormClose(false)} hoverColor="#ffdddd" />
            <Button type="submit" text="Guardar" hoverColor="#ddffdd" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveDrawForm;
