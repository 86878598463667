import React, { useState, useEffect, useCallback } from "react";
import { fetchColumnData } from "../services/displayDataService";
import { handleCatch } from "../utils/handleCatch";
import "../css/DropDown.css";

const DropDown = ({ columnName, columnKey = "", checkboxes, onUpdateFilter }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // Updates selected checboxes here and in the parent
  const handleCheckboxChange = (checkboxValue) => {
    const newSelectedCheckboxes = selectedCheckboxes.includes(checkboxValue)
      ? selectedCheckboxes.filter((item) => item !== checkboxValue)
      : [...selectedCheckboxes, checkboxValue];

    setSelectedCheckboxes(newSelectedCheckboxes);
    onUpdateFilter(columnName, newSelectedCheckboxes);
  }

  // Select | Deselect all checkboxes
  const handleAllCheckboxChange = (event) => {
    if (event.target.checked) {
      const newOptions = dropdownOptions.map((option) => option.name);
      setSelectedCheckboxes(newOptions);
      onUpdateFilter(columnName, newOptions);
    } else {
      setSelectedCheckboxes([]);
      onUpdateFilter(columnName, null);
    }
  }

  // Updates component when parent changes props
  useEffect(() => {
    setSelectedCheckboxes(checkboxes || []);
    setIsOpen(!!checkboxes);
  }, [checkboxes]);

  // Gets column data when opening the dropdown
  const handleFetchColumnData = useCallback(async () => {
    try {
      const data = await fetchColumnData(columnName);
      setDropdownOptions(data);
    } 
    catch (err) { handleCatch(err); }
  }, [columnName])

  useEffect(() => {
    if (isOpen) handleFetchColumnData();
    else {
      onUpdateFilter(columnName, null);
    } // eslint-disable-next-line
  }, [isOpen]);

  // ---------- //
  return (
    <div className="dropdown-main">
      <div style={{ display: "flex", flexDirection: "column"}} onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}>
        {columnKey && (
          <span className="dropdown-title">{columnKey}</span>
        )}
        <span className="dropdown-title">{columnName}</span>
      </div>
      {isOpen && (
        <div className="horizontal-line">
          <div className="dropdown-all">
            <span htmlFor={`select-all-${columnName}`}>Todo</span>
            <input
              type="checkbox"
              id={`select-all-${columnName}`}
              onChange={handleAllCheckboxChange}
              checked={
                selectedCheckboxes.length === dropdownOptions.length &&
                dropdownOptions.length > 0
              }
            />
            <span htmlFor={`select-all-${columnName}`}>{dropdownOptions.length}</span>
          </div>
          {dropdownOptions.map((column, index) => (
            <div className="dropdown-checkboxes" key={index}>
              <span htmlFor={`checkbox-${columnName}-${index}`}>({column.count})</span>
              <span htmlFor={`checkbox-${columnName}-${index}`}>{column.name}</span>
              <input
                type="checkbox"
                id={`checkbox-${columnName}-${index}`}
                value={column.name}
                onChange={() => handleCheckboxChange(column.name)}
                checked={selectedCheckboxes.includes(column.name)}
              />
              <span htmlFor={`checkbox-${columnName}-${index}`}>{index + 1}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
