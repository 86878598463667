import React, { useCallback, useEffect, useState } from 'react'
import { saveGroup, updateGroup, getGroup } from '../../../services/filterGroupService';
import { handleCatch } from '../../../utils/handleCatch';
import Button from '../../Button';

/**
 * FilterManager.jsx
 * @param {Variable} updateForm - If there is an id the form is for updating a group, if there is nothing its for saving a group
 * @param {Function} onFormClose - Function to close the form and update parents filter list if a draw (as a filter) was stored.
 */
const SaveGroupForm = ({ updateForm, onFormClose }) => {
  const [groupID, setGroupID] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  // Uses form data to create a new group
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("Por favor introduce el nombre del grupo.");
      return;
    } 

    // If updating the group add property id
    const newGroup = { 
      name, 
      type: type ? type : null,
      groupID,
    };

    updateForm ? handleUpdateGroup(newGroup) : handleSaveGroup(newGroup);
  }

  // Stores a new group into the db
  const handleSaveGroup = async (formData) => {
    try { await saveGroup(formData); } 
    catch (err) { handleCatch(err); }
    finally { onFormClose(true); }
  }

  // Updates a new group in the db
  const handleUpdateGroup = async (formData) => {
    try { await updateGroup(formData); } 
    catch (err) { handleCatch(err); }
    finally { onFormClose(true); }
  }

  // Fetch group data
  const fetchGroupData = useCallback(async (groupID) => {
    try {
      const groupData = await getGroup(groupID);
      setGroupID(groupData.groupID);
      setName(groupData.name);
      setType(groupData.type);
    } 
    catch (err) { handleCatch(err); }
  }, []);
  
  // When rendering component, if update, loads group data
  useEffect(() => {
    if (updateForm) {
      setGroupID(updateForm);
      fetchGroupData(updateForm);
    }
  }, [updateForm, fetchGroupData]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>
              <b>Nombre del grupo</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
            </label>
          </div>

          <div>
            <label>
              <b>Tipo de grupo</b>
              <select
                value={type === "" ? "Sin grupo" : type}
                onChange={(e) => setType(e.target.value)}
                style={{ maxHeight: '150px', overflowY: 'auto'}}
              >
                <option value="">Sin tipo</option>
                <option value="apply">Aplicar</option>
                <option value="remove">Quitar</option>
              </select>
            </label>
          </div>

          <div className="button-group">
            <Button type="button" text="Cancelar" onClick={() => onFormClose(false)} hoverColor="#ffdddd" />
            <Button type="submit" text={updateForm ? "Modificar" : "Guardar"} hoverColor="#ddffdd" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveGroupForm;